;(function (factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['jquery'], factory);
    } else if (typeof exports !== 'undefined') {
        module.exports = factory(require('jquery'));
    } else {
        factory(jQuery);
    }
}(function ($) {
    $.partner = {
        init: function () {
            if ($('#partner-form').length) {
                const partnerImage = $('#partner_image');
                partnerImage.parent().hide();
                $('#image').click(function () {
                    $.partner.imageClick();
                });
                $('#image-btn').click(function () {
                    $.partner.imageClick();
                });
                partnerImage.on('change', function () {
                    $.partner.displayImage();
                })
            }
        },
        imageClick: function () {
            $('#partner_image').click();
        },
        displayImage: function(){
            const files = $('#partner_image').prop('files');
            const fileReader = new FileReader();
            fileReader.onload = function () {
                const base64 = fileReader.result;
                $('#image').attr('src', base64);
            };
            fileReader.readAsDataURL(files[0]);
        }
    }
}));