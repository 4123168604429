;(function (factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['jquery'], factory);
    } else if (typeof exports !== 'undefined') {
        module.exports = factory(require('jquery'));
    } else {
        factory(jQuery);
    }
}(function ($) {
    let wordSentencesFormSubmitProcess = false;
    $.sentence = {
        init: function () {
            this.selectWords();
            this.submitForm();
        },
        selectWords: function () {
            $('#add_selected_word').click(function () {
                $('#original_words option:selected').each(function () {
                    $('#sentence_words').append($(this).clone());
                    $('#sentence_words_custom').append($(this).clone());
                });
            });
            $('#remove_selected_word').click(function () {
                $('#sentence_words option:selected').each(function () {
                    $(this).remove();
                });
                $('#sentence_words_custom option:selected').each(function () {
                    $(this).remove();
                });
            });
        },
        submitForm: function () {
            $('#sentence-form').submit(function (event) {
                if (!wordSentencesFormSubmitProcess) {
                    wordSentencesFormSubmitProcess = true;
                    event.preventDefault();
                    $('#sentence_save').prop('disabled', true);
                    $('#sentence_words option').each(function () {
                        $(this).prop('selected', true);
                    });
                    $('#sentence_words_custom option').each(function () {
                        $(this).prop('selected', true);
                    });
                    $('#sentence-form').submit();
                }
            });
        },
    }
}));