;(function (factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['jquery'], factory);
    } else if (typeof exports !== 'undefined') {
        module.exports = factory(require('jquery'));
    } else {
        factory(jQuery);
    }
}(function ($) {
    $.evaluation = {
        init: function () {
            if ($('#evaluation-form').length) {
                this.initAudioEvents();
                this.setCorrectAndFalseCheckboxes();
                this.initCorrectAndFalseCheckboxesEvents();
                this.initAnswersEvents();
            }
        },
        initAudioEvents: function () {
            $('#evaluation_is_audio').change(function(){
                $.evaluation.initAudioDisplay();
            });
            $.evaluation.initAudioDisplay();
        },
        initAudioDisplay: function () {
            if ($('#evaluation_is_audio').prop('checked')) {
                $('#audio-block').show();
            } else {
                $('#audio-block').hide();
            }
        },
        setCorrectAndFalseCheckboxes: function () {

            /** Correct value **/
            const correctQuestionField = $('#evaluation_correct_question');
            if (correctQuestionField.val() !== "") {
                $('#evaluation_correct_type_1').prop('checked', true);
            } else {
                $('#evaluation_correct_type_0').prop('checked', true);
            }

            /** False value **/
            const falseQuestionField = $('#evaluation_false_question');
            if (falseQuestionField.val() !== "") {
                $('#evaluation_false_type_1').prop('checked', true);
            } else {
                $('#evaluation_false_type_0').prop('checked', true);
            }

            $.evaluation.setCorrectAndFalseValues();
        },
        initCorrectAndFalseCheckboxesEvents: function () {

            /** Correct checkboxes **/
            $('input[name="evaluation[correct_type]"]').change(function () {
                $.evaluation.setCorrectAndFalseValues();
            });

            /** False checkboxes **/
            $('input[name="evaluation[false_type]"]').change(function () {
                $.evaluation.setCorrectAndFalseValues();
            });
        },
        setCorrectAndFalseValues: function () {
            /** Correct checkboxes **/
            const correctValue = $('input[name="evaluation[correct_type]"]:checked').val();
            if (correctValue === 'question') {
                $('#evaluation_correct_level').hide(0, function(){$(this).val(null)});
                $('#evaluation_correct_question').show();
            } else {
                $('#evaluation_correct_question').hide(0, function(){$(this).val(null)});
                $('#evaluation_correct_level').show();
            }

            /** False checkboxes **/
            const falseValue = $('input[name="evaluation[false_type]"]:checked').val();
            if (falseValue === 'question') {
                $('#evaluation_false_level').hide(0, function(){$(this).val(null)});
                $('#evaluation_false_question').show();
            } else {
                $('#evaluation_false_question').hide(0, function(){$(this).val(null)});
                $('#evaluation_false_level').show();
            }
        },
        initAnswersEvents: function () {

            // set data in first load
            $('.answer-types').each(function () {
                const isChecked = $(this).find('input:checked');
                if (!isChecked.length) {
                    $(this).find('input').first().prop('checked', true);
                }
                $.evaluation.setAnswersDisplay($(this).find('input:checked'));
            });

            // init events
            $('.answer-types input').change(function () {
                $.evaluation.setAnswersDisplay($(this));
            });
            $('.answer-is-correct').change(function () {
                if($(this).prop('checked')){
                    $('.answer-is-correct').each(function(){
                        $(this).prop('checked', false);
                    });
                }
                $(this).prop('checked', true);
            });
        },
        setAnswersDisplay: function (input) {
            const parent = input.parent().parent();
            const answerId = parent.attr('data-answer');
            if (input.val() === 'text') {
                $('#answer-' + answerId + '-i18n-block').show();
                $('#answer-' + answerId + '-audio-block').hide();
            } else {
                $('#answer-' + answerId + '-audio-block').show();
                $('#answer-' + answerId + '-i18n-block').hide();
            }
        }
    }
}));