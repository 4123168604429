/**
 * CSS
 */
import './styles/brain.scss';
import './styles/brain.scss';
import 'summernote/dist/summernote.css';
import 'bootstrap-select/dist/css/bootstrap-select.min.css'


/**
 * REQUIRES
 */
require('popper.js');
require('bootstrap-select');
require('jquery');
require("jquery-ui/ui/widgets/sortable");
require('bootstrap');
require('multi.js/dist/multi.min');
require('axios');
require('summernote');
require('./scripts/brain/main');
require('./scripts/brain/user');
require('./scripts/brain/levels');
require('./scripts/brain/words');
require('./scripts/brain/sentence');
require('./scripts/brain/faq');
require('./scripts/brain/theme');
require('./scripts/brain/evaluation');
require('./scripts/brain/culture');
require('./scripts/brain/formulaDetail');
require('./scripts/brain/formula');
require('./scripts/brain/promo');
require('./scripts/brain/partner');
require('./scripts/sound');
require('./scripts/brain/dialogue');
require('./scripts/brain/audioOrder');
require('./scripts/brain/text');
require('./scripts/brain/stats');
require('./scripts/brain/media')
require('./scripts/brain/payment')

$(document).ready(function () {
    $.sound.init();
    $.main.init();
    $.user.init();
    $.levels.init();
    $.words.init();
    $.sentence.init();
    $.faq.init();
    $.theme.init();
    $.evaluation.init();
    $.culture.init();
    $.formulaDetail.init();
    $.formula.init();
    $.promo.init();
    $.partner.init();
    $.dialogue.init();
    $.audioOrder.init();
    $.text.init();
    $.payment.init();
    $.stats.init();
    $.media.init();
});
