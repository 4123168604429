;(function (factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['jquery'], factory);
    } else if (typeof exports !== 'undefined') {
        module.exports = factory(require('jquery'));
    } else {
        factory(jQuery);
    }
}(function ($) {
    $.culture = {
        init: function () {
            this.textarea();
        },
        textarea: function () {
            $('.content').summernote({
                height: 300,
                toolbar: [
                    [ 'style', [ 'style' ] ],
                    [ 'font', [ 'bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear'] ],
                    [ 'fontname', [ 'fontname' ] ],
                    [ 'fontsize', [ 'fontsize' ] ],
                    [ 'color', [ 'color' ] ],
                    [ 'para', [ 'ol', 'ul', 'paragraph', 'height' ] ],
                    [ 'table', [ 'table' ] ],
                    [ 'insert', [ 'link', 'picture'] ],
                    [ 'view', [ 'undo', 'redo', 'fullscreen', 'codeview', 'help' ] ]
                ],
                callbacks: {
                    onImageUpload: function (files, editor, welEditable) {
                        sendFile(files[0], editor, welEditable, $(this));
                    }
                }
            });
            function sendFile(file, editor, welEditable, content) {
                var data = new FormData();
                data.append('image', file);
                $.ajax({
                    type: "POST",
                    url: "/" + $('.content').attr('data-language') + "/image/uploads",
                    dataType: "json",
                    data: data,
                    cache: false,
                    contentType: false,
                    processData: false,
                    success: function (response) {
                        if (response.success) {
                            var image = $('<img>').attr('src', response.url);
                            content.summernote('insertNode', image[0]);
                        }
                    }
                });
            }
        }
    }
}));