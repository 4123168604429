;(function (factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['jquery'], factory);
    } else if (typeof exports !== 'undefined') {
        module.exports = factory(require('jquery'));
    } else {
        factory(jQuery);
    }
}(function ($) {
    $.audioOrder = {
        init: function () {
            this.displayDeleteButton();
            this.initDeleteAudioOrder();
            this.initAddAudioOrder();
            this.initSortable();
        },
        initAddAudioOrder: function() {
            $('.add-audio-order').click(function () {
                //On clone le dernier élément
                var lastElement = $('.audio-order-content:last');
                var newAudioOrder = lastElement.clone();

                //On vide l'input audio et l'input caché
                newAudioOrder.find('.audioOrderContent').val('');
                newAudioOrder.find('.audioOrderContentAvailable').val('');
                //On cache la lecture de l'audio et on affiche l'input audio
                newAudioOrder.find('.audio-play').hide();
                newAudioOrder.find('.audio-upload').removeClass('d-none');

                //On place le nouvel élément en dernier
                newAudioOrder.insertAfter(lastElement);
                $.audioOrder.initDeleteAudioOrder();
            });
        },
        initDeleteAudioOrder: function() {
            $('.delete-audio-order').click(function(){
                $(this).parents('.audio-order-content').remove();
                $.audioOrder.displayDeleteButton();
                $("#sortable").sortable('refresh');
            });
        },
        displayDeleteButton: function() {
            $('.delete-audio-order').hide();
            $('.delete-audio-order').not(':first').show();
            $('.delete-audio-order').not(':first').addClass('d-flex');
            $('.delete-audio-order').first().removeClass('d-flex');
            $('.delete-audio-order').hide();
        },
        initSortable: function() {
            $("#sortable").sortable({update: $.audioOrder.displayDeleteButton, handle: '.handle'});
        }
    }
}));