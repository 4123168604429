import axios from "axios";

;(function (factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['jquery'], factory);
    } else if (typeof exports !== 'undefined') {
        module.exports = factory(require('jquery'));
    } else {
        factory(jQuery);
    }
}(function ($) {
    let levelFormSubmitProcess = false;
    $.levels = {
        init: function () {
            this.initDisplayBonus();
            this.bonusToggle();
            this.sortable();
            this.selectLessons();
            this.selectCultures();
            this.selectGames();
            this.submitForm();
            this.isGraphemeToggle();
            this.initDisplayIsGrapheme();
            this.preventRequired();
        },
        preventRequired: function () {
            $('.btn-form').click(function () {
                let hasRequired = false;
                $('input,textarea,select').each(function () {
                    if ( $(this).val().length == 0 && $(this).prop('required')) {
                        $(this).addClass('is-invalid');
                        hasRequired = true;
                    }
                });
                if (hasRequired == true) {
                    $(window).scrollTop(0);
                    $('.required-warning').slideUp( 300 ).delay( 300 ).fadeIn( 400 );
                }
            });
        },
        isGraphemeToggle: function () {
            const graphemeField = $('#level_is_grapheme_level');
            graphemeField.click(function () {
                $.levels.initDisplayIsGrapheme();
            });
        },
        initDisplayIsGrapheme: function () {
            const graphemeField = $('#level_is_grapheme_level');
            const wordsBlock = $('#words-block');
            const sentencesBlock = $('#sentences-block');
            if (
                graphemeField.is(':checked')
            )
            {
                wordsBlock.hide();
                sentencesBlock.hide();
            }
            else
            {
                wordsBlock.show();
                sentencesBlock.show();
            }
        },
        sortable: function () {
            $(".levels-sortable").sortable({
                appendTo: document.body,
                revert: true,
                axis: "y",
                update: function (event, ui) {
                    const items = [];
                    $(this).children().each(function (idx, val) {
                        items.push($(this).attr('data-item'));
                    });
                    axios({
                        method: 'post',
                        url: "/" + $(this).attr('data-language') + "/levels/positions",
                        data: {
                            levels: items
                        }
                    }).then((response) => {
                        console.log(response);
                    });
                }
            });
        },
        initDisplayBonus: function () {
            if ($('#level_is_bonus').length) {


                if ($('#level_is_bonus').is(':checked')) {
                    // display words bonus list
                    $('#level_words').next().hide(function () {
                        $('#level_words_for_bonus').next().show();
                    });
                    // display sentences bonus list
                    $('#level_sentences').next().hide(function () {
                        $('#level_sentences_for_bonus').next().show();
                    });
                    // display texts bonus list
                    $('#level_texts').next().hide(function () {
                        $('#level_texts_for_bonus').next().show();
                    });
                    // display dialogues bonus list
                    $('#level_dialogues').next().hide(function () {
                        $('#level_dialogues_for_bonus').next().show();
                    });
                    // display audioOrders bonus list
                    $('#level_audio_orders').next().hide(function () {
                        $('#level_audio_orders_for_bonus').next().show();
                    });
                    // hide exclude_previous_words field
                    $('#level_exclude_previous_words').parent().parent().hide();
                } else {
                    // hide words bonus list
                    $('#level_words_for_bonus').next().hide(function () {
                        $('#level_words').next().show();
                    });
                    // hide sentences bonus list
                    $('#level_sentences_for_bonus').next().hide(function () {
                        $('#level_sentences').next().show();
                    });
                    // hide texts bonus list
                    $('#level_texts_for_bonus').next().hide(function () {
                        $('#level_texts').next().show();
                    });
                    // hide dialogues bonus list
                    $('#level_dialogues_for_bonus').next().hide(function () {
                        $('#level_dialogues').next().show();
                    });
                    // hide audio orders bonus list
                    $('#level_audio_orders_for_bonus').next().hide(function () {
                        $('#level_audio_orders').next().show();
                    });
                    // display exclude_previous_words field
                    $('#level_exclude_previous_words').parent().parent().show();
                }
            }
        },
        bonusToggle: function () {
            $('#level_is_bonus').change(function () {
                $.levels.initDisplayBonus();
            });
        },
        selectLessons: function () {
            $('#add_selected_lesson').click(function () {
                $('#original_lessons option:selected').each(function () {
                    $('.lessons-choice').append($(this).clone());
                    $(this).remove();
                });
            });
            $('#remove_selected_lesson').click(function () {
                $('.lessons-choice option:selected').each(function () {
                    $('#original_lessons').append($(this).clone());
                    $(this).remove();
                });
            });
            $('#up_selected_lesson').click(function () {
                const selected = $('.lessons-choice option:selected');
                const first = selected.first();
                const before = first.prev();
                if (before.length > 0) {
                    selected.detach().insertBefore(before);
                }
            });
            $('#down_selected_lesson').click(function () {
                const selected = $('.lessons-choice option:selected');
                const last = selected.last();
                const after = last.next();
                if (after.length > 0) {
                    selected.detach().insertAfter(after);
                }
            });
        },
        selectCultures: function () {
            $('#add_selected_culture').click(function () {
                $('#original_cultures option:selected').each(function () {
                    $('.cultures-choice').append($(this).clone());
                    $(this).remove();
                });
            });
            $('#remove_selected_culture').click(function () {
                $('.cultures-choice option:selected').each(function () {
                    $('#original_cultures').append($(this).clone());
                    $(this).remove();
                });
            });
            $('#up_selected_culture').click(function () {
                const selected = $('.cultures-choice option:selected');
                const first = selected.first();
                const before = first.prev();
                if (before.length > 0) {
                    selected.detach().insertBefore(before);
                }
            });
            $('#down_selected_culture').click(function () {
                const selected = $('.cultures-choice option:selected');
                const last = selected.last();
                const after = last.next();
                if (after.length > 0) {
                    selected.detach().insertAfter(after);
                }
            });
        },
        selectGames: function () {
            $('#add_selected_game').click(function () {
                $('#original_games option:selected').each(function () {

                    // level page
                    const levelGame = $('#level_games');
                    if (levelGame.length) {
                        levelGame.append($(this).clone());
                    }

                    // theme level page
                    const themeLevelGame = $('#theme_level_games');
                    if (themeLevelGame.length) {
                        themeLevelGame.append($(this).clone());
                    }

                    $(this).remove();
                });
            });
            $('#remove_selected_game').click(function () {

                // level page
                const levelGame = $('#level_games');
                if (levelGame.length) {
                    $('#level_games option:selected').each(function () {
                        $('#original_games').append($(this).clone());
                        $(this).remove();
                    });
                }

                // theme level page
                const themeLevelGame = $('#theme_level_games');
                if (themeLevelGame.length) {
                    $('#theme_level_games option:selected').each(function () {
                        $('#original_games').append($(this).clone());
                        $(this).remove();
                    });
                }
            });
            $('#up_selected_game').click(function () {
                let selected;
                const levelGame = $('#level_games');
                const themeLevelGame = $('#theme_level_games');
                if (levelGame.length) {
                    selected = $('#level_games option:selected');
                } else if (themeLevelGame.length) {
                    selected = $('#theme_level_games option:selected');
                } else {
                    return;
                }
                const first = selected.first();
                const before = first.prev();
                if (before.length > 0) {
                    selected.detach().insertBefore(before);
                }
            });
            $('#down_selected_game').click(function () {
                let selected;
                const levelGame = $('#level_games');
                const themeLevelGame = $('#theme_level_games');
                if (levelGame.length) {
                    selected = $('#level_games option:selected');
                } else if (themeLevelGame.length) {
                    selected = $('#theme_level_games option:selected');
                } else {
                    return;
                }
                const last = selected.last();
                const after = last.next();
                if (after.length > 0) {
                    selected.detach().insertAfter(after);
                }
            });
        },
        submitForm: function () {
            $('#level-form').submit(function (event) {
                if (!levelFormSubmitProcess) {
                    levelFormSubmitProcess = true;
                    event.preventDefault();

                    if ($('#level_save').length) {
                        // level page
                        $('#level_save').prop('disabled', true);;
                    } else if ($('#theme_level_save').length) {
                        // theme level page
                        $('#theme_level_save').prop('disabled', true);;
                    }

                    // level page
                    if($('.lessons-choice').length){
                        $('.lessons-choice option').each(function () {
                            $(this).prop('selected', true);
                        });
                    }

                    if($('.cultures-choice').length){
                        $('.cultures-choice option').each(function () {
                            $(this).prop('selected', true);
                        });
                    }

                    if ($('#level_games').length) {
                        // level page
                        $('#level_games option').each(function () {
                            $(this).prop('selected', true);
                        });
                    } else if ($('#theme_level_games').length) {
                        // theme level page
                        $('#theme_level_games option').each(function () {
                            $(this).prop('selected', true);
                        });
                    }

                    $('#level-form').submit();
                }
            });
        },
    }
}));