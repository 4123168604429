;(function (factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['jquery'], factory);
    } else if (typeof exports !== 'undefined') {
        module.exports = factory(require('jquery'));
    } else {
        factory(jQuery);
    }
}(function ($) {
    $.dialogue = {
        init: function () {
            this.displayDeleteButton();
            this.initSelectCaracter();
            this.initDeleteDialogue();
            this.initAddDialogue();
            this.initSortable();
        },
        initSelectCaracter: function() {
            /*
            if($('select.selectpicker').length > 0) {
                $('select.selectpicker').selectpicker({
                    dropupAuto: false
                });
            }
            */
        },
        initAddDialogue: function() {
            $('.add-dialogue').click(function () {
                //On clone le dernier élément
                var lastElement = $('.dialogue-content:last');
                var newDialogue = lastElement.clone();

                //On supprime le selectpicker du nouvel élément et on le regénère
                newDialogue.find('.bootstrap-select button').remove();
                newDialogue.find('select.selectpicker').selectpicker({
                    dropupAuto: false
                });
        
                //On purge le texte du textarea
                newDialogue.find('textarea').val('');

                //On place le nouvel élément en dernier
                newDialogue.insertAfter(lastElement);
                $.dialogue.initDeleteDialogue();
                $.dialogue.displayDeleteButton();
            });
        },
        initDeleteDialogue: function() {
            $('.delete-dialogue').click(function(){
                $(this).parents('.dialogue-content').remove();
                $.dialogue.displayDeleteButton();
                $("#sortable").sortable('refresh');
            });
        },
        displayDeleteButton: function() {
            $('.delete-dialogue').hide();
            $('.delete-dialogue').not(':first').show();
            $('.delete-dialogue').not(':first').addClass('d-flex');
            $('.delete-dialogue').first().removeClass('d-flex');
        },
        initSortable: function() {
            $('#sortable').sortable({
                stop: $.dialogue.displayDeleteButton,
                handle: '.handle'
            });
        }
    }
}));