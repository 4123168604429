import axios from "axios";

;(function (factory) {
    'use srtict';
    if (typeof define === 'function' && define.amd) {
        define(['jquery'], factory);
    } else if (typeof exports !== 'undefined') {
        module.exports = factory(require('jquery'));
    } else {
        factory(jQuery);
    }
}(function ($) {
    $.formula = {
        init: function () {
            this.sortable();
        },
        sortable: function () {
            $(".formula-sortable").sortable({
                appendTo: document.body,
                revert: true,
                axis: "y",
                update: function (event, ui) {
                    const items = [];
                    $(this).children().each(function (idx, val) {
                        items.push($(this).attr('data-item'));
                    });
                    axios({
                        method: 'post',
                        url: "/" + $(this).attr('data-language') + "/formulas/positions",
                        data: {
                            formulas: items
                        }
                    }).then((response) => {
                        console.log(response);
                    });
                }
            });
        }
    }
}));