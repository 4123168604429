; (function (factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['jquery'], factory);
    } else if (typeof exports !== 'undefined') {
        module.exports = factory(require('jquery'));
    } else {
        factory(jQuery);
    }
}(function ($) {
    $.payment = {
        init: function () {
            $("#spinner-payment").hide();
            $('#paymenetType').on('change', function () {
                {
                    $("#spinner-payment").show();
                    let select = $('#paymenetType').val();
                    $.payment.updatePaymentItem(select);
                }
            })

        },
        updatePaymentItem: function (select) {
            {
                console.warn(select)
               // return;
                $.ajax({
                    url: '/fr/payment/apiParam/' + select,
                    method: 'GET',
                    success: function (response) {
                        let table = $("#myTable > tbody")
                        table.empty()
                        $("#spinner-payment").hide();
                        for (let index = 0; index < response.payementSuccess.length; index++) {
                            table.append(
                                `<tr>
                                <td>
                                <strong>${response.payementSuccess[index].user}<strong> <br/>
                                ${response.payementSuccess[index].email}
                                </td>
                                <td>${$.payment.formatDate(response.payementSuccess[index].date_payment)}</td>
                                <td>${response.payementSuccess[index].reference}</td>
                                <td>${response.payementSuccess[index].type}</td>
                                <td>${$.payment.formatCurrency(response.payementSuccess[index].amount / 100)}</td>
                                <td><img src="/assets/img/payments/${response.payementSuccess[index].origin.toLowerCase()}.svg" width="45px" class="rounded" alt="${"STRIPE".toLowerCase()}" title="${"STRIPE".toLowerCase()}"></td>
                                <td>${response.payementSuccess[index].response_code == "succeeded" ? "<span class='material-icons text-success'>done</span>" : "<span class='material-icons text-danger'>close</span>"}</td>
                                <td>
                                    ${response.payementSuccess[index].logs.length != 0 ?
                                    "<button type='button' class='btn btn-outline-secondary btn-sm nunito-bold' data-toggle='modal' data-backdrop='static' data-target='#payment-logs-" + response.payementSuccess[index].reference + "'>Logs</button>" :
                                    "<button type='button' class='btn btn-outline-secondary btn-sm nunito-bold disabled'>Logs</button>"
                                }
                                    ${(response.payementSuccess[index].logs.length != 0) ? (
                                    $.payment.showModal(response.payementSuccess[index].logs, response.payementSuccess[index])
                                ) : (' ')}
                                </td>
                                </tr>`
                            );
                        }
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        console.log(xhr);
                    },
                })
            }
        },
        showModal: function (logs, payments) {
            let modal = `
            <div
            class="modal fade"
            id="payment-logs-${payments.reference}"
            tabindex="-1"
            role="dialog">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Paiement ${payments.reference}</h5>
                        <button type="button" class="close text-primary" data-dismiss="modal" aria-label="Close">
                            <span class="material-icons">close</span>
                        </button>
                    </div>
                        <div class="modal-body text-left">`
            for (let index = 0; index < logs.length; index++) {
                modal += `<div class="pt-3 ${logs.length > 1 ? 'border-bottom' : ' '}">  <div class="d-flex align-items-center justify-content-between mb-3">`
                modal += `<div>
                    <div>
                        <span class="nunito-bold">Nom de fichier :</span>
                        ${logs[index].filename}
                    </div>
                    <div class="mt-2">
                        <span class="nunito-bold">Dernière modification :</span>
                        ${logs[index].updatedAt}
                    </div>
                    <div class="mt-2">
                        <span class="nunito-bold">Contenu du fichier :</span>
                    </div>
                </div>
                <div>
                    <a href="${'/fr/users/log-payment/' + logs[index].filename}"
                    target="_blank" class="btn btn-outline-primary btn-sm nunito-bold">Télécharger</a>
                </div>
                </div>
                            <div class="bg-dark p-3">
                                <pre class="text-success">${logs[index].content}</pre>
                            </div>
                `
                modal += `</div> </div>`
            }
            modal += `</div></div></div></div>`
            return modal
        },
        formatCurrency: function (price) {
            return new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(
                price,
            )
        },
        dateFormater: function (date) {
            return new Date(date)
        },
        padTo2Digits: function (num) {
            return num.toString().padStart(2, '0');
        },
        formatDate: function (date) {
            date = new Date(date);
            return (
                [
                    $.payment.padTo2Digits(date.getMonth() + 1),
                    $.payment.padTo2Digits(date.getDate()),
                    date.getFullYear(),
                ].join('/') +
                ' ' +
                [
                    $.payment.padTo2Digits(date.getHours()),
                    $.payment.padTo2Digits(date.getMinutes()),
                    $.payment.padTo2Digits(date.getSeconds()),
                ].join(':')
            );
        }
    }
}));