;(function (factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['jquery'], factory);
    } else if (typeof exports !== 'undefined') {
        module.exports = factory(require('jquery'));
    } else {
        factory(jQuery);
    }
}(function ($) {
    $.main = {
        init: function () {
            this.dualList();
            this.dataToggle();
            this.confirmTarget();
            this.filenameOfInput();
        },
        dualList: function () {
            $('.dual-list').multi({
                enable_search: false
            });
            $('.dual-list-searchable').multi({
                search_placeholder: "Rechercher...",
            });
        },
        dataToggle: function () {
            $('[data-bs-toggle="offcanvas"]').on('click', function () {
                $('.offcanvas-collapse').toggleClass('open')
            });
        },
        confirmTarget: function () {
            $('[data-type="confirm"]').on('click', function () {
                let message = $(this).attr('data-message');
                if (message === "" || message === undefined) {
                    message = "Voulez-vous vraiment continuer ?";
                }
                let target = $(this).attr('data-target');
                if (
                    confirm(message) &&
                    !["", undefined, '#'].includes(target)
                ) {
                    location.href = $(this).attr('data-target');
                }
            });
        },
        filenameOfInput: function(){
            $('input[type="file"]').change(function(e){
                $(this).next('.custom-file-label').html(e.target.files[0].name);
            });
        }
    };
}));

