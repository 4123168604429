import axios from "axios";

;(function (factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['jquery'], factory);
    } else if (typeof exports !== 'undefined') {
        module.exports = factory(require('jquery'));
    } else {
        factory(jQuery);
    }
}(function ($) {
    let levelFormSubmitProcess = false;
    $.text = {
        init: function () {
            this.selectGames();
            this.submitForm();
        },
        selectGames: function () {
            $('#add_selected_word').click(function () {
                $('#original_words option:selected').each(function () {
                    $('#game_text_words').append($(this).clone());
                });
            });
            $('#remove_selected_word').click(function () {
                $('#game_text_words option:selected').each(function () {
                    $(this).remove();
                });
            });
            $('#up_selected_word').click(function () {
                let selected;
                selected = $('#game_text_words option:selected');

                const first = selected.first();
                const before = first.prev();
                if (before.length > 0) {
                    selected.detach().insertBefore(before);
                }
            });
            $('#down_selected_word').click(function () {
                let selected;
                selected = $('#game_text_words option:selected');

                const last = selected.last();
                const after = last.next();
                if (after.length > 0) {
                    selected.detach().insertAfter(after);
                }
            });
        },
        submitForm: function () {
            $('#text-form').submit(function (event) {
                if (!levelFormSubmitProcess) {
                    levelFormSubmitProcess = true;
                    event.preventDefault();

                    $('#game_text_words option').each(function () {
                        $(this).prop('selected', true);
                    });

                    $('#text-form').submit();
                }
            });
        }
    }
}));