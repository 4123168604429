;(function (factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['jquery'], factory);
    } else if (typeof exports !== 'undefined') {
        module.exports = factory(require('jquery'));
    } else {
        factory(jQuery);
    }
}(function ($) {
    $.words = {
        init: function () {
            this.declensionIsDefault();
            this.initDisplayConjugation();
            this.initDisplayDeclension();
            this.conjugationToggle();
            this.declensionToggle();
            this.textArea();
        },
        textArea: function () {
            $('.content-word').summernote({
                toolbar: [
                    ['style', ['bold', 'italic', 'underline', 'clear']],
                    ['para', ['ul', 'ol']]
                ]
            });
        },
        declensionIsDefault: function () {
            $('.declension-is-default').click(function() {
                $('.declension-is-default').not(this).prop('checked', false);
            });
        },
        initDisplayConjugation: function () {
            const natureField = $('#word-form #word_nature');
            const conjugationBlock = $('#word-form #conjugation');
            const conjugationIrregularBlock = $('#word-form #conjugation-irregular');
            if (
                natureField.length &&
                natureField.val() &&
                natureField.val() === 'VERB' &&
                conjugationBlock.length

            )
            {
                conjugationBlock.show();
            }
            else
            {
                conjugationBlock.hide();
            }

            if (
                natureField.length &&
                natureField.val() &&
                natureField.val() === 'IRREGULAR_VERB' &&
                conjugationIrregularBlock.length

            )
            {
                conjugationIrregularBlock.show();
            }
            else
            {
                conjugationIrregularBlock.hide();
            }
        },
        initDisplayDeclension: function () {
            const natureField = $('#word-form #word_id_nature');
            const allDeclensionBlock = $('#word-form #declension-all');
            const simpleDeclensionBlock = $('#word-form #declension-simple');
            if (
                natureField.length &&
                natureField.val() &&
                natureField.val() === 'PERSONAL_PRONOUN' &&
                allDeclensionBlock.length &&
                simpleDeclensionBlock.length
            )
            {
                allDeclensionBlock.hide();
                simpleDeclensionBlock.show();
            }
            else if (!allDeclensionBlock.length)
            {
                allDeclensionBlock.hide();
                simpleDeclensionBlock.show();
            }
            else
            {
                simpleDeclensionBlock.hide();
                allDeclensionBlock.show();
            }
        },
        declensionToggle: function () {
            const natureField = $('#word-form #word_id_nature');
            natureField.change(function () {
                $.words.initDisplayDeclension();
            });
        },
        conjugationToggle: function () {
            const natureField = $('#word-form #word_nature');
            natureField.change(function () {
                $.words.initDisplayConjugation();
            });
        }
    }
}));