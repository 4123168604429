import axios from "axios";

;(function (factory) {
    'use srtict';
    if (typeof define === 'function' && define.amd) {
        define(['jquery'], factory);
    } else if (typeof exports !== 'undefined') {
        module.exports = factory(require('jquery'));
    } else {
        factory(jQuery);
    }
}(function ($) {
    $.faq = {
        init: function () {
            this.sortable();
            this.textarea();
        },
        sortable: function () {
            $(".faq-sortable").sortable({
                appendTo: document.body,
                revert: true,
                axis: "y",
                update: function (event, ui) {
                    const items = [];
                    $(this).children().each(function (idx, val) {
                        items.push($(this).attr('data-item'));
                    });
                    axios({
                        method: 'post',
                        url: "/" + $(this).attr('data-language') + "/brain/admin/faq/positions",
                        data: {
                            faqs: items
                        }
                    }).then((response) => {
                        console.log(response);
                    });
                }
            });
            $(".category-sortable").sortable({
                appendTo: document.body,
                revert: true,
                axis: "y",
                update: function (event, ui) {
                    const items = [];
                    $(this).children().each(function (idx, val) {
                        items.push($(this).attr('data-item'));
                    });
                    axios({
                        method: 'post',
                        url: "/" + $(this).attr('data-language') + "/brain/admin/faq/category/positions",
                        data: {
                            categories: items
                        }
                    }).then((response) => {
                        console.log(response);
                    });
                }
            });
        },
        textarea: function () {
            $(".textarea-content").summernote({
                height: 300,
                toolbar: [
                    [ 'style', [ 'style' ] ],
                    [ 'font', [ 'bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear'] ],
                    [ 'fontname', [ 'fontname' ] ],
                    [ 'fontsize', [ 'fontsize' ] ],
                    [ 'color', [ 'color' ] ],
                    [ 'para', [ 'ol', 'ul', 'paragraph', 'height' ] ],
                    [ 'table', [ 'table' ] ],
                    [ 'insert', [ 'link'] ],
                    [ 'view', [ 'undo', 'redo', 'fullscreen', 'codeview', 'help' ] ]
                ]
            });
        }
    }
}));