;(function (factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['jquery'], factory);
    } else if (typeof exports !== 'undefined') {
        module.exports = factory(require('jquery'));
    } else {
        factory(jQuery);
    }
}(function ($) {
    $.user = {
        init: function () {
            if ($('#user-form').length) {
                this.pageLoad();
                this.changeEvents();
                this.displayEval();
                this.displaySkill();
                this.displayResult();
                this.displayEvalAnswers();
                this.displayLevelResults();
            }
            this.loadActiveTab();
            this.changeTabEvent();
        },
        pageLoad: function () {
            $.user.toggleFormula(
                $('#user_subscription_formula').find(':selected').attr('data-free')
            );
            $('#password-on').hide();
        },
        changeEvents: function () {
            $('#user_subscription_formula').on('change', function () {
                $.user.toggleFormula(
                    $(this).find(':selected').attr('data-free')
                );
            });
            $('#password-off').on('click', function () {
                $('#user_password').attr('type', 'text');
                $(this).hide();
                $('#password-on').show();
            });
            $('#password-on').on('click', function () {
                $('#user_password').attr('type', 'password');
                $(this).hide();
                $('#password-off').show();
            });
        },
        changeTabEvent: function() {
            $('.nav-pills a').on('click', function (e) {
                var tabId = $(e.target).attr('data-bs-target').substr(1);
                $.user.saveActiveTab(tabId);
            });
        },
        saveActiveTab: function (tabId) {
            localStorage.setItem('activeTab', tabId);
        },
        loadActiveTab: function () {
            var activeTab = localStorage.getItem('activeTab');
            if (activeTab) {
                $('.nav-pills a[data-bs-target="#' + activeTab + '"]').tab('show');
            }
        },
        toggleFormula: function (isFree) {
            if (isFree == '0') {
                $('#premium-section').show();
            } else {
                $('#premium-section').hide();
            }
        },
        displayEval: function(){
            //Affichage des tests d'évaluation
            $('.eval-lang').click(function(){
                $('.eval-lang').addClass('btn-light').removeClass('btn-primary');
                $(this).addClass('btn-primary').removeClass('btn-light');
                $(this).blur();

                var target = $(this).attr('data-target');
                $('.evals').addClass('d-none');
                $('#evals-' + target).removeClass('d-none');
            });

            //Clique par défaut sur le premier bouton
            $('.eval-lang').eq(0).click();
        },
        displaySkill: function(){
            //Affichage des tests d'évaluation
            $('.skill-lang').click(function(){
                $('.skill-lang').addClass('btn-light').removeClass('btn-primary');
                $(this).addClass('btn-primary').removeClass('btn-light');
                $(this).blur();

                var target = $(this).attr('data-target');
                $('.skills').addClass('d-none');
                $('#skills-' + target).removeClass('d-none');
            });

            //Clique par défaut sur le premier bouton
            $('.skill-lang').eq(0).click();
        },
        displayResult: function(){
            //Affichage des tests d'évaluation
            $('.result-lang').click(function(){
                $('.result-lang').addClass('btn-light').removeClass('btn-primary');
                $(this).addClass('btn-primary').removeClass('btn-light');
                $(this).blur();

                var target = $(this).attr('data-target');
                $('.results').addClass('d-none');
                $('#results-' + target).removeClass('d-none');
            });

            //Clique par défaut sur le premier bouton
            $('.result-lang').eq(0).click();
        },
        displayEvalAnswers: function(){
            $('.eval-title').click(function(){
                var content = $(this).next('.eval-content');
                if(content.hasClass('d-none'))
                {
                    $('.eval-title .material-icons').html('keyboard_arrow_right');
                    $(this).children('.material-icons').html('keyboard_arrow_down');
                    $('.eval-content').addClass('d-none');
                    content.removeClass('d-none');
                }
                else
                {
                    $('.eval-title .material-icons').html('keyboard_arrow_right');
                    content.addClass('d-none');
                }
            });
        },
        displayLevelResults: function(){
            $('.level-title').click(function(){
                var content = $(this).next('.level-content');
                if(content.hasClass('d-none'))
                {
                    $('.level-title .material-icons').html('keyboard_arrow_right');
                    $(this).children('.material-icons').html('keyboard_arrow_down');
                    $('.level-content').addClass('d-none');
                    content.removeClass('d-none');

                    //Si l'appel a déjà été fait pour la résupération des résultats on ignonre
                    if(content.children('.games').length == 0) {
                        var levelId = $(this).attr('data-id');
                        var langCode = $(this).attr('data-lang');
                        var user = $(this).attr('data-user');
                        var url = '/' + langSrc + '/' + 'users/level-result/' + levelId + '/' + user + '/' + langCode;

                        $.ajax({
                            url: url,
                            method: 'GET',
                            beforeSend: function () {
                                content.children('.loader').removeClass('d-none');
                            },
                            complete: function () {
                                content.children('.loader').addClass('d-none');
                            },
                            success: function (response) {
                                if (response.status != 200) {
                                    content.children('.alert-danger').removeClass('d-none');
                                } else {
                                    content.children('.alert-danger').addClass('d-none');
                                    content.html(response.view.content);
                                }
                            },
                            error: function () {
                                content.children('.alert-danger').removeClass('d-none');
                            }
                        });
                    }
                }
                else
                {
                    $('.level-title .material-icons').html('keyboard_arrow_right');
                    content.addClass('d-none');
                }
            });
        }
    }
}));