;(function(factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['jquery'], factory);
    } else if (typeof exports !== 'undefined') {
        module.exports = factory(require('jquery'));
    } else {
        factory(jQuery);
    }
}(function($) {
    $.sound = {
        init: function() {
            this.playSound();
        },
        playSound: function() {
            $('[data-type="sound"]').on('click', function () {
                const sound =
                 new Audio('/assets/media/audio/' + 
                 $(this).attr('data-target'));
                sound.play().then();
            });
        }
    }
}));