import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

(function (factory) {
  "use strict";
  if (typeof define === "function" && define.amd) {
    define(["jquery"], factory);
  } else if (typeof exports !== "undefined") {
    module.exports = factory(require("jquery"));
  } else {
    factory(jQuery);
  }
})(function ($) {
  $.stats = {
    init: function () {
      this.graphUser();
      this.chartUserCount();
      this.chartpaymentsCount();
      this.graphLevelResult();
    },
    graphUser: function () {
      let today = new Date()
        .toLocaleDateString("pt-br")
        .split("/")
        .reverse()
        .join("-");
      let dateStart = $("#dateStart").val(today);
      let dateEnd = $("#dateEnd").val(today);

      $("#mybutton").on("click", function () {
        $("#spinner-graph").show();
        $("#mystats").hide();
        $.ajax({
          url: "/brain/stats/users/api/" + dateStart.val() + "/" + dateEnd.val(),
          method: "GET",
          success: function (response) {
            $("#allNewUser").html(response.allNewUser.length);
            $("#allUserConnectToday").html(response.allUserConnectToday);
            $("#allNewUserPremium").html(response.allNewUserPremium);
            $("#allNewResellerUser").html(response.allNewResellerUser);
            $.stats.chartGenerator(response);
          },
          error: function (xhr, ajaxOptions, thrownError) {
            console.log(xhr);
          },
        });
      });

      let curenturl = window.location.href;
      curenturl.includes("/fr/stats") && $.ajax({
        url: "/brain/stats/users/api/" + today + "/" + today,
        method: "GET",
        success: function (response) {
          $.stats.chartGenerator(response);
        },
        error: function (xhr, ajaxOptions, thrownError) {
          console.log(xhr);
        },
      });
    },
    chartGenerator: function (response) {
      const lastChart = Chart.getChart("mystats");
      if (lastChart) {
        lastChart.destroy();
      }
      $("#spinner-graph").hide();
      $("#mystats").show();
      const ctx = document.getElementById("mystats");
      let tabOrgine = [];
      let tabNombre = [];
      for (let index = 0; index < response.dataGraff.length; index++) {
        tabOrgine.push(response.dataGraff[index].origin);
        tabNombre.push(response.dataGraff[index].number);
      }
      let stats = new Chart(ctx, {
        type: "bar",
        data: {
          labels: tabOrgine,
          datasets: [
            {
              label: "Origin",
              data: tabNombre,
              borderWidth: 1,
            },
          ],
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: 'Origines des utilisateurs sur la periode'
            },
          },
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
    chartUserCount: function () {
      let curenturl = window.location.href;
      curenturl.includes("/fr/stats") && $.ajax({
        url: "/brain/stats/users/api/chart-count",
        method: "GET",
        success: function (response) {
          const labels = response.labels;
          const countUsers = response.data[0];
          const countNewUsers = response.data[1];
          const chartEl = document.getElementById('statsUserCount');
          const chart = new Chart(chartEl, {
            type: 'bar',
            data: {
              labels: labels,
              datasets: [
                {
                  label: "Existants",
                  data: countUsers,
                  borderWidth: 1,
                  stack: 'Stack 0'
                },
                {
                  label: "Nouveaux",
                  data: countNewUsers,
                  borderWidth: 1,
                  stack: 'Stack 0',
                  backgroundColor: 'lightblue'
                },
              ],
            },
            options: {
              plugins: {
                title: {
                  display: true,
                  text: 'Nombres d\'utilisateurs (cumul)'
                },
              },
              scales: {
                y: {
                  stacked: true
                },
                x: {
                  stacked: true
                }
              },
            }
          });
        },
        error: function (xhr, ajaxOptions, thrownError) {
          console.log(xhr);
        },
      });
    },
    chartpaymentsCount: function () {
      let curenturl = window.location.href;
      curenturl.includes("/fr/payment") && $.ajax({
        url: "/fr/payment/api/chart-count",
        method: "GET",
        success: function (response) {
          const labels = response.labels;
          const countPayments = response.data[0];
          const countNewPayments = response.data[1];
          const countCPFPayments = response.data[2];
          const countB2BPayments = response.data[3];
          const countLPM = response.data[4];

          const maxLength = Math.max(countPayments.length, countNewPayments.length, countCPFPayments.length, countB2BPayments.length, countLPM.length);
          let totalSomme = Array.from({ length: maxLength }, (_, index) => {
            return (countNewPayments[index] || 0) +
              (countCPFPayments[index] || 0) +
              (countB2BPayments[index] || 0) +
              (countLPM[index] || 0);
          });


          const chartEl = document.getElementById('statsPaymentsCount');
          // Chart global
          const chart = new Chart(chartEl, {
            type: 'bar',
            data: {
              labels: labels,
              datasets: [
                {
                  label: "Précédent",
                  data: countPayments,
                  borderWidth: 1,
                  stack: 'Stack 0'
                },
                {
                  label: "Nouveau",
                  data: countNewPayments,
                  borderWidth: 1,
                  stack: 'Stack 0',
                  backgroundColor: 'lightblue'
                },
              ],
            },
            options: {
              plugins: {
                title: {
                  display: true,
                  text: 'Chiffre d\'affaire B2C cumulé'
                },
              },
              scales: {
                //barThickness: 20,
                //categoryPercentage: 0.5,
                //barPercentage: 0.5,
                y: {
                  stacked: true,
                  ticks: {
                    font: {
                      size: 14,
                    },
                  },
                  //barThickness: 20,
                  //categoryPercentage: 0.5,
                  //barPercentage: 0.5
                },
                x: {
                  stacked: true,
                  ticks: {
                    font: {
                      size: 14,
                    }
                  },
                },

              },
            }
          });

          const chartGlobalEl = document.getElementById('statsPaymentsCountGlobal');
          // Chart détails
          const chartGlobal = new Chart(chartGlobalEl, {
            type: 'bar',
            data: {
              labels: labels,
              datasets: [
                /*
                {
                  label: "BtoB",
                  data: countB2BPayments,
                  borderWidth: 1,
                  stack: 'Stack 0',
                  backgroundColor: 'DarkSeaGreen'
                },
                {
                  label: "CPF",
                  data: countCPFPayments,
                  borderWidth: 1,
                  stack: 'Stack 0',
                  backgroundColor: 'AntiqueWhite'
                },
                */
                {
                  label: "BtoC",
                  data: countNewPayments,
                  borderWidth: 1,
                  stack: 'Stack 0',
                  backgroundColor: 'lightblue'
                },
                /*
                {
                  label: "LPM",
                  data: countLPM,
                  borderWidth: 1,
                  stack: 'Stack 0',
                  backgroundColor: 'LightCoral'
                },
                */
                {
                  label: "Total",
                  data: totalSomme,
                  borderWidth: 1,
                  stack: 'Stack 1',
                  backgroundColor: 'turquoise'
                },
              ],
            },
            options: {
              plugins: {
                title: {
                  display: true,
                  text: 'Chiffre d\'affaire global'
                },
              },
              scales: {
                //barThickness: 20,
                //categoryPercentage: 0.5,
                //barPercentage: 0.5,
                y: {
                  stacked: true,
                  ticks: {
                    font: {
                      size: 14,
                    },
                  },
                  //barThickness: 20,
                  //categoryPercentage: 0.5,
                  //barPercentage: 0.5
                },
                x: {
                  stacked: true,
                  ticks: {
                    font: {
                      size: 14,
                    }
                  },
                },
              }
            }
          });

        },
        error: function (xhr, ajaxOptions, thrownError) {
          console.log(xhr);
        },
      });
    },
    graphLevelResult: function () {
      $.ajax({
        url: '/fr/stats/users/api-level',
        method: 'GET',
        success: function (response) {
          $.stats.graphLevelCreator(response);
        },
        error: function (xhr, ajaxOptions, thrownError) {
          console.log(xhr);
        },
      })
    },
    graphLevelCreator: function (response) {
      let lastChart = Chart.getChart("statsUserLevel");
      if (lastChart) {
        lastChart.destroy()
      }
      let statsUserLevel = document.getElementById('statsUserLevel');
      const levelLocal = $.stats.levelsMaxPosition(
        response?.levels,
        response?.levelsArray
      )
      let translation = levelLocal.map(obj => obj.translation)
      let nbr = levelLocal.map(obj => obj.nbr)
      if(levelLocal.length == 0 ){
        return;
      }
      const graph = new Chart(statsUserLevel, {
        type: 'bar',
        data: {
          labels: translation,
          datasets: [
            {
              label: 'Utilisateur par marche ',
              data: nbr,
              borderWidth: 1,
              backgroundColor: 'indigo',
              stack: 'Stack 3'
            },
          ],
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: 'Utilisateur par marche '
            },
          },
          scales: {
            y: {
              stacked: true
            },
            x: {
              stacked: true
            }
          },
        }
      })
    },
    levelsMaxPosition: function (levels, levelArray) {
      let levelTab = {};
      if (levelArray) {
        for (let level of levelArray) { // erreur console : undefined is not iterable
          if (level.id) {
            if (levelTab[level.translation]) {
              levelTab[level.translation].nbr += 1;
            } else {
              levelTab[level.translation] = {
                nbr: 1,
                translation: level.translation,
                position: level.position
              };
            }
          }
        }
      }
      if (levels) {
        for (let levelall of levels) { // erreur console : undefined is not iterable
          if (!levelTab[levelall.translation]) {
            levelTab[levelall.translation] = {
              nbr: 0,
              translation: levelall.translation,
              position: levelall.position
            };
          }
        }
      }
      return Object.values(levelTab).sort((a, b) => a.position > b.position ? 1 : -1);
    },

  };
});
