import axios from "axios";
(function (factory) {
    "use strict";
    if (typeof define === "function" && define.amd) {
        define(["jquery"], factory);
    } else if (typeof exports !== "undefined") {
        module.exports = factory(require("jquery"));
    } else {
        factory(jQuery);
    }
})(function ($) {
    $.media = {
        init: function () {
            this.sortable();
        },
        sortable: function () {
            $(".media-sortable").sortable({
                appendTo: document.body,
                revert: true,
                axis: "y",
                update: function (event, ui) {
                    const items = [];
                    $(this).children().each(function (idx, val) {
                        items.push($(this).attr('data-item'));
                    });
                    axios({
                        method: 'post',
                        url: "positions",
                        data: {
                            medias: items
                        }
                    }).then((response) => {
                        console.log(response);
                    });
                }
            });
        },
    }
})