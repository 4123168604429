import axios from "axios";

;(function (factory) {
    'use srtict';
    if (typeof define === 'function' && define.amd) {
        define(['jquery'], factory);
    } else if (typeof exports !== 'undefined') {
        module.exports = factory(require('jquery'));
    } else {
        factory(jQuery);
    }
}(function ($) {
    $.promo = {
        init: function () {
            this.generateCode();
            this.formulaToggle();
            this.initDisplayFormulas();
        },
        generateCode: function () {
            const promoInput = $('#promo_code');
           $('#promo_button').click( function () {
               $.ajax({
                   type: 'GET',
                   url: $(this).attr('data-url') + 'fr/tools/generate/promo-code',
                   headers: {
                       'apiKey': '86c919054af616e9700b02983fb1856e',
                   },
                   success: function(data) {
                       promoInput.val(data);
                   },
                   error: function(err) {
                       console.log('error:' + err)
                   }
               });
           });
            $(document).on({
                ajaxStart: function() {
                    $('#loading-code').removeClass("d-none");
                    $('#promo_button').addClass("d-none")
                },
                ajaxStop: function() {
                    $('#loading-code').addClass("d-none");
                    $('#promo_button').removeClass("d-none")
                },
            });
        },
        initDisplayFormulas: function () {
            const natureField = $('#promo_hasPackage');
            const formulaBlock = $('#form-formulas');
            const packageBlock = $('#form-packages');
            if (
                natureField.val() == 1
            )
            {
                formulaBlock.hide();
                packageBlock.show();
            }
            else
            {
                formulaBlock.show();
                packageBlock.hide();
            }
        },
        formulaToggle: function () {
            const natureField = $('#promo_hasPackage');
            natureField.change(function () {
                $.promo.initDisplayFormulas();
            });
        },
    }
}));